<template>
  <va-card>
    <va-card-title>
      <slot name="title">
        <div>
          {{ $t("templates.title") }}
        </div>
      </slot>
    </va-card-title>

    <!-- Table -->
    <va-card-content>
      <va-data-table
        :no-data-html="$t('templates.noResults')"
        :columns="columns"
        :items="state.templates"
      >
        <template #header(id)>{{ $t("templates.id") }}</template>
        <template #header(productId)>{{ $t("templates.productId") }}</template>
        <template #header(seats)>{{ $t("templates.seats") }}</template>
        <template #header(isTrial)>{{ $t("templates.isTrial") }}</template>
        <template #header(validityDuration)>{{ $t("templates.validityDuration") }}</template>

        <template #cell(id)="{ source: id }">
          <router-link :to="{ path: templateLink(id) }">
            {{ id }}
          </router-link>
          <button-clipboard-copy :field="$t('templates.id')" :value="id" />
        </template>

        <template #cell(productId)="{ source: productId }">
          <span class="badges">
            {{ productId }}
          </span>
        </template>

        <template #cell(seats)="{ source: seats }">
          {{ seats !== undefined ? seats : $t('templates.unlimited') }}
        </template>

        <template #cell(isTrial)="{ source: isTrial }">
          {{ isTrial ? $t('general.yes') : $t('general.no') }}
        </template>

        <template #cell(validityDuration)="{ source: validityDuration }">
          {{ validityDuration }}
        </template>
      </va-data-table>
    </va-card-content>
    <va-card-content>
      <va-pagination
        class="pagination"
        input
        color="#000000"
        :hide-on-single-page="true"
        v-model="state.currentPage"
        :pages="state.totalPages"
        :page-size="state.perPage"
      />
    </va-card-content>
  </va-card>
</template>

<style scoped>
.has-tip::after {
  font-family: "Glyphicons Halflings";
  content: "\e085";
  margin-left: 1px;
}
.badges {
  display: flex;
  gap: 5px;
}
</style>

<script setup>
import { reactive, watch, onMounted, inject } from "vue";
import { callFetchTemplates } from "@/api/licensing";
import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";
import { templateLink } from "@/utils/licensing";

const handleError = inject("handleError");


const columns = ["id", "productId", "seats", "isTrial", "validityDuration"];

const state = reactive({
  templates: [],
  loading: false,
  currentPage: 1,
  totalItems: 0,
  perPage: 10,
  totalPages: 0
});

const props = defineProps({
  params: {
    type: Object
  }
});

/**
 * Create the parameters object for the request and call it.
 * Then calculate the page properties's values.
 * @param {number} page - positive integer
 */
async function loadTemplates(page = 1) {
  state.loading = true;

  const params = buildUrlParams(page);

  try {
    const resp = await callFetchTemplates({ params });
    if (resp?.data) {
      const templates = resp.data.items.map(item => ({
        id: item?.id,
        productId: item?.productId,
        seats: item?.seats,
        isTrial: item?.license?.type === "UserLocked" && !item?.license?.keyFormat,
        validityDuration: item?.validityDuration,
      }));

      state.templates = templates;

      updatePagination(resp.data.total, page);
    }
  } catch (e) {
    handleError(e);
  }

  state.loading = false;
}

function buildUrlParams(page = 1) {
  const params = new URLSearchParams();

  Object.keys(props.params).forEach(key => {
    if (props.params[key] !== undefined) {
      if (Array.isArray(props.params[key])) {
        props.params[key].forEach(p => params.append(key, p));
      } else {
        params.append(key, props.params[key]);
      }
    }
  });

  params.set("count", state.perPage);
  params.set("page", page);

  return params;
}

function updatePagination(totalItems, page = 1) {
  state.totalItems = totalItems;
  state.currentPage = page;
  state.totalPages =
    Math.floor(totalItems / state.perPage) +
    (totalItems % state.perPage === 0 ? 0 : 1);
}

watch(
  () => state.currentPage,
  async currentPage => {
    await loadTemplates(currentPage);
  }
);

watch(
  () => props.params,
  async () => {
    await loadTemplates();
  }
);

onMounted(async () => {
  await loadTemplates();
});
</script>
